import React from 'react';
import styled from 'styled-components';
import RichText from "utils/richText";
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import SEO from "utils/seo";
import SliceZoneSwitch from 'utils/sliceZoneSwitch';

const BlogWrapper = styled.section`
	width: 100%;
	height: 100%;
	background-color: var(--background-color);
	color: white;
	text-align: left;
	padding: 40px 0;	

	>div {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;

		> .gatsby-image-wrapper{
			height: fit-content;
			margin-bottom: 30px;
			border: 10px solid white;
		}

		h1,h2,h3 {
			font-family: "Noto Sans JP";
		}

		.ratio {
			display: grid;
			padding: 20px 0;
			grid-template-columns: 1fr;
			max-width: inherit;
			background: #353030; /* just for instance */
			margin-bottom: 20px;
		  }
		  
		  .ratio::before {
			content: '';
			width: 0;
			padding-bottom: calc(100% / (16/9)); /* here you can place any ratio */
			grid-area: 1 / 1 / 1 / 1;
		  }
		  
		  .ratio>*:first-child {
			padding: 0 20px;
			width: 100%;
			height: 100%;
			grid-area: 1 / 1 / 1 / 1; /* the same as ::before */
			background: #353030 /* just for instance */
		  }

    > h1 {
			font-size: var(--landing-fontSize);
			line-height: 3.5rem;
			margin: 0 0 30px 0;
			
			@media (max-width: 600px){
				line-height: 2.5rem;
			}
    }

		>h2 {
			font-size: var(--heading-fontSize);
			line-height: 2.5rem;
			border-top: 1px solid white;
			margin-top: 0;
			padding: 30px 10px;
		}

		>h3 {
      		margin: 30px 0px 20px 0px;
			padding: 20px 5px;
			font-size: var(--subHeading-fontSize);
			line-height: 1.8rem;
			background-color: rgba(0,0,0,0.1);
		}

		>p {
			margin-bottom: 20px;
			padding: 0 20px;
			line-height: 33px;
			font-size: var(--body-fontSize);

			a {
				color: #2a9ee2;
					padding: 5px;

				&:hover {
					background-color: white;

					&::after{
					content: " ↗";
					}
				}
			}
		}
  }
`;

export const query = graphql`
	query BlogQuery($id: String) {
		prismic {
			allBlogs(id: $id) {
				edges {
					node {
						_meta {
							uid
						}
						seo_title
						seo_description
						page_title
						image
						imageSharp {
							childImageSharp {
								fluid{
									...GatsbyImageSharpFluid_withWebp
									}
							}
						}
						body {
							... on PRISMIC_BlogBodyImage_block {
								type
								label
								primary {
									type
									image
									imageSharp {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp
											}
										}
									}
								}
							}
							... on PRISMIC_BlogBodyContent_block {
								type
								label
								primary {
									content
								}
							}
							... on PRISMIC_BlogBodyCall_to_action {
								type
								label
								primary {
									text
									type
									link {
										... on PRISMIC_Page {
											_meta {
												uid
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

const Blog = ({ data }) => {
	if(!data){
		return null;
	}
	
  const title = data?.prismic?.allBlogs?.edges[0]?.node?.page_title;
	const image = data?.prismic?.allBlogs?.edges[0]?.node?.imageSharp.childImageSharp.fluid;
	const body = data?.prismic?.allBlogs?.edges[0]?.node.body;
	
	return (
		<>
		<SEO title={data.prismic.allBlogs.edges[0].node.seo_title} description={data.prismic.allBlogs.edges[0].node.seo_description}/>
		<BlogWrapper>
      <div className="content">
        <RichText render={title}/>
				<Img fluid={image} imgStyle={{ objectFit: 'contain' }}/>
				{!!body && <SliceZoneSwitch body={body}/>}
      </div>
		</BlogWrapper>
		</>
	);
};

export default Blog;
